import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

export const ConfirmDialog = ({ open, setOpen, action }) => {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={() => {
      setOpen(false);
    }}>
      <DialogTitle>Are you sure</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton>
          <Close onClick={() => {
            setOpen(false);
          }} />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>
          Please confirm that you want to {action} this service
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained"
          onClick={() => {
            setOpen(false);
          }}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
